'use client';
import _split from 'lodash/split';
import _floor from 'lodash/floor';
import _forEach from 'lodash/forEach';
import _last from 'lodash/last';
import _size from 'lodash/size';
import { usePathname } from 'next/navigation';
import {
  babyIntermediaryUrls,
  toddlerIntermediaryUrls,
  preSchoolerIntermediaryUrls,
  childIntermediaryUrls,
  teenaagerIntermediaryUrls,
} from './chartUrlsByAgeAndGender';
import { plural } from 'pluralize';
import { getLifeStage } from './lm_title_description_service';
import { parseUrlForGenderWithString } from './maps';
import { parseUrlForSimpleChartType } from './lm_utilities';

const XDate = require('xdate');
// import { useRouter } from 'next/router';

const formulateAgeUrlFragment = function (formObj, age) {
  var _age = '';
  // console.log(formObj)
  /*
     params: `age` is fetched in months
     */
  if (age <= 3) {
    var dob = new XDate(formObj.dob);
    var measure_dob = new XDate(formObj.md);
    _age = _floor(dob.diffWeeks(measure_dob)) + '-week-old-baby';
  } else if (age > 3 && age < 4) {
    _age = '12-week-old-baby';
  } else if (age >= 4 && age <= 11) {
    _age = _floor(age) + '-month-old-baby';
  } else if (age > 11 && age < 12) {
    _age = _floor(age) + '-month-old-baby';
  } else if (age === 12) {
    _age = '1-year-old-baby';
  } else if (age > 12 && age < 13) {
    _age = '1-year-old-baby';
  } else if (age >= 13 && age <= 23) {
    _age = _floor(age) + '-month-old';
  } else if (age > 23 && age < 24) {
    _age = _floor(age) + '-month-old';
  } else if (age === 24) {
    _age = '2-year-old';
  } else if (age > 24 && age < 25) {
    _age = '2-year-old';
  } else if (age >= 25 && age < 36) {
    var q = _floor(age / 12);
    var r = _floor(age % 12);
    if (r === 1) {
      _age = q + '-years-' + r + '-month';
    } else if (r === 0) {
      _age = q + '-years-old';
    } else {
      _age = q + '-years-' + r + '-months';
    }
  } else if (age === 36) {
    _age = '3-years-old';
  } else if (age > 36) {
    var q = _floor(age / 12);
    var r = _floor(age % 12);
    if (r === 1) {
      _age = q + '-years-' + r + '-month';
    } else if (r === 0) {
      _age = q + '-years-old';
    } else {
      _age = q + '-years-' + r + '-months';
    }
  }
  return _age;
};

const formulateGenderUrlFragment = function (gender) {
  var g_fragment = '';

  switch (gender) {
    case 'm':
      g_fragment = '/boys/';
      break;
    case 'f':
      g_fragment = '/girls/';
      break;
    default:
      g_fragment = '/boys/';
      break;
  }

  return g_fragment;
};

const formulateGenderFromUrlFragment = function (url) {
  let gender = '';
  let genderurl = splitUrlToParts()[2];

  switch (genderurl) {
    case 'boys':
      gender = 'm';
      break;
    case 'girls':
      gender = 'f';
      break;
    default:
      gender = 'm';
      break;
  }

  return gender;
};

const formulateChartUrl = function (formObj, type, age, urlUnit) {
  var url = '/';
  var _type = type;
  var _gender = formulateGenderUrlFragment(formObj.gender);
  var _age = formulateAgeUrlFragment(formObj, age);
  return url + _type + _gender + _age + (urlUnit ? `/${urlUnit}` : '');
};

const calculateDobPerLocation = function (location) {
  var myDate = new Date();
  var dayOfMonth = myDate.getDate();
  var loc_array = _split(location, '/');
  var lastElement = _last(loc_array);
  var lastElementArray = _split(lastElement, '-'),
    days = 0;

  if (lastElementArray[1] === 'year' || lastElementArray[1] === 'years') {
    days = parseInt(lastElementArray[0]) * 366;
    if (_size(lastElementArray) > 3 && (_last(lastElementArray) === 'month' || _last(lastElementArray) === 'months')) {
      days = days + parseInt(lastElementArray[2]) * 31;
    }
    myDate.setDate(dayOfMonth - days); // Subtracted 1 for a issue where the date was wrong
  } else if (lastElementArray[1] === 'month' || lastElementArray[1] === 'months') {
    days = parseInt(lastElementArray[0]) * 31;
    myDate.setDate(dayOfMonth - days);
  } else if (lastElementArray[1] === 'week' || lastElementArray[1] === 'weeks') {
    days = parseInt(lastElementArray[0]) * 7;
    myDate.setDate(dayOfMonth - days);
  } else {
    myDate.setDate(dayOfMonth - 365);
  }

  return myDate;
};

const splitUrlToParts = function () {
  const router = usePathname();
  // return window.location.pathname.split('/');
  return router.split('/');
};

const getFileName = (blurbData, hashArray, url) => {
  let fileName =
    hashArray[3] +
    blurbData?.ageDesc +
    ' ' +
    plural(getLifeStage(url)) +
    ' ' +
    parseUrlForSimpleChartType(splitUrlToParts()[0]) +
    ' ' +
    parseUrlForGenderWithString(hashArray) +
    ' chart';
  fileName = fileName.toLowerCase().replaceAll(' ', '-');

  return fileName;
};

const getChartImageAltValue = (blurbData, hashArray, url) => {
  let fileName =
    blurbData?.ageDesc +
    ' ' +
    plural(getLifeStage(url)) +
    ' ' +
    parseUrlForSimpleChartType(splitUrlToParts()[0]) +
    ' ' +
    parseUrlForGenderWithString(hashArray) +
    ' chart';
  fileName = fileName.toLowerCase();
  return fileName;
};

export {
  babyIntermediaryUrls,
  toddlerIntermediaryUrls,
  preSchoolerIntermediaryUrls,
  childIntermediaryUrls,
  teenaagerIntermediaryUrls,
  formulateChartUrl,
  calculateDobPerLocation,
  formulateGenderFromUrlFragment,
  getFileName,
  getChartImageAltValue,
};
